import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment'
import HolidayChart from './HolidayChart';
import Select from 'react-select';

const currentYear = new Date().getFullYear();

function HolidayData() {
  const [holidays, setHolidays] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [showTishaBav, setShowTishaBav] = useState(false);
  const [showPurim, setShowPurim] = useState(false);

  const yearOptions = [
    { value: 2022, label: '2022' },
    { value: 2023, label: '2023' },
    { value: 2024, label: '2024' },
    { value: 2025, label: '2025' },
    { value: 2026, label: '2026' },
    { value: 2027, label: '2027' },
    // Add more year options as needed
  ];

  const handleTishaBavChange = (event) => {
    setShowTishaBav(event.target.checked);
  };
  
  const handlePurimChange = (event) => {
    setShowPurim(event.target.checked);
  };
  

  useEffect(() => {
    const fetchHolidays = async (year) => {
      try {
        const response = await axios.get(
          `https://www.hebcal.com/hebcal?v=1&cfg=json&maj=on&min=off&mod=off&nx=off&year=${year}&month=x&s=off&leyning=off&mf=off&c=off&geo=none&M=off&s=off`
        );
        const data = response.data; // JSON format API response
        setHolidays(data.items); // Store the holiday items in the component state
        setLoading(false);
      } catch (error) {
        console.error('Error fetching holidays:', error);
      }
    };

    if (selectedYear) {
      fetchHolidays(selectedYear);
    }
  }, [selectedYear]); // Fetch the data whenever the selected year changes
  
  //filtering out chol Hamoed days by filtering out Holidays that have parentheses, for example Pesach IV (CH'H'M)
  //AND filtering ojut holidays that fall out on weekends i.e. only show holidays that fall out on days 2-6
  const filteredHolidays = holidays.filter((item) => {
    if (item.yomtov || item.title === "Tish’a B’Av" || item.title === "Purim" )  {
      const holidayDate = moment(item.date);
      const dayOfWeek = holidayDate.day();
      
      // Filter only if the holiday falls on a USA workday (Monday to Friday)
      return dayOfWeek >= 1 && dayOfWeek <= 5 && !item.title.includes('(');
    }
    return false;
  }).map(item => {
    // Add a flag to indicate if the holiday is optional
    if (item.title === "Tish’a B’Av" || item.title === "Purim") {
      return { ...item, optional: true };
    }
    return { ...item, optional: false };
  });

  const holidaysToDisplay = filteredHolidays.filter(item => {
    // Include mandatory holidays
    if (!item.optional) return true;
    // Include optional holidays based on checkbox states
    if (item.title === "Tish’a B’Av" && showTishaBav) return true;
    if (item.title === "Purim" && showPurim) return true;
    return false;
  });

  // Get total numbers of days
  const totalHolidays = holidaysToDisplay.length;


  const romanToNumeric = (romanNumeral) => {
    const numerals = {
      I: 1,
      II: 2,
      III: 3,
      IV: 4,
      V: 5,
      VI: 6,
      VII: 7,
      VIII: 8
      // Add more Roman numerals as needed
    };

    //Change day number from Roman to Normal
    return numerals[romanNumeral] || romanNumeral;
  };

  function modifyHolidayTitles(filteredHolidays) {
    return filteredHolidays.map((item) => {
      const modifiedTitle = item.title.replace(/(.*?)([IVX]+)(.*)/, (match, p1, p2, p3) => {
        const romanNumeral = p2.trim();
        const numericValue = romanToNumeric(romanNumeral);
        let dayOrdinal;
        if (numericValue === 1) {
          dayOrdinal = 'st';
        } else if (numericValue === 2) {
          dayOrdinal = 'nd';
        } else if (numericValue === 3) {
          dayOrdinal = 'rd';
        } else {
          dayOrdinal = 'th';
        }
        return `${p1.trim()} (${numericValue}${dayOrdinal} day)${p3.trim()}`;
      });
      return { ...item, title: modifiedTitle };
    });
  }

  const modifiedFilteredHolidays = modifyHolidayTitles(holidaysToDisplay);

  return (
    <div >
    {/* <h1>Holiday Data</h1> */}
    <div className="year-select">
        {/* <label className="year-select-title" htmlFor="yearSelect">Select Year:</label> */}
        <Select
          id="yearSelect"
          options={yearOptions}
          value={selectedYear}
          onChange={(selectedOption) => setSelectedYear(selectedOption.value)}
          placeholder={`${selectedYear}`}
          //className="year-select"
        />
      </div>
      <div className="extra-holiday-container">
        <label>
          <input type="checkbox" checked={showTishaBav} onChange={handleTishaBavChange} />
          Tisha B’Av
        </label>
      </div>

      <div className="extra-holiday-container">
        <label>
          <input type="checkbox" checked={showPurim} onChange={handlePurimChange} />
          Purim
        </label>
      </div>

      {/* <HolidayChart holidays={modifiedFilteredHolidays} /> */}
      <p className="total-holidays">
        Total days: {totalHolidays}
     </p>
     <HolidayChart holidays={modifiedFilteredHolidays} />
    
      {/* <ul style={{ listStyleType: 'none', padding: 0 }}>
        {filteredHolidays.map((item, index) => (
          <li key={index}>
            <h4>{item.title}</h4>
            <p>Date: {item.date}</p>
            <p>Hebrew Date: {item.hdate}</p>
            <p>Category: {item.category}</p>
            <p>Subcategory: {item.subcat}</p>
            {item.yomtov && <p>Yomtov</p>}
            <p>Hebrew: {item.hebrew}</p>
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              More information
            </a>
          </li>
        ))}
      </ul> */}
    </div>
  );
}

export default HolidayData;
