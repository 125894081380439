import React from 'react';

const BuyMeACoffeeButton = () => {
  return (
    <a href="https://www.buymeacoffee.com/jewishdayoff" target="_blank" rel="noopener noreferrer">
      <img
        src="https://cdn.buymeacoffee.com/buttons/v2/default-blue.png"
        alt="Buy Me A Coffee"
        style={{ height: '50px', width: '205px' }}
      />
    </a>
  );
};

export default BuyMeACoffeeButton;
