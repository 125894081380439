import React from 'react';

function Header() {
    return (
      <header className="app-header">
        <h1>YomTovs That Fall On Workdays</h1>
        <p className="header-text">Find out when to take off work for Jewish holidays!</p>
      </header>
    );
  }

  export default Header;